import { useDialog } from "@component-utils/dialogs"
import SummaryPreview from "./SummaryPreview.vue"
import SummaryDocumentsApi from "~/api/ContractSummaries/SummaryDocumentsApi"
import { useErrorToast } from "@component-utils/toasts"
export type HeaderType = 'Clause' | 'Standard' | 'Updated' | 'Approval status' | 'Approver'

export default async function openDialog(id: number | string, associatedId: string | undefined = undefined){
  try{
    const {
      clauses,
      summary_name: summaryName,
      parties,
      entries,
      headers
    } = await SummaryDocumentsApi.get<{
      clauses: Backend.Models.SummaryClause[],
      summary_name: string,
      parties: Backend.Models.Participant[],
      entries: {
        name: string,
        value: string
      }[],
      headers: HeaderType[]
    }>({id: id})
    useDialog(SummaryPreview, {clauses: clauses, summaryName: summaryName, parties: parties, attributes: entries, headers: headers, docId: associatedId})
  }catch(e){
    useErrorToast(e)
  }
}